

<section id="mobileHeader" class="mobileView">
    <div class="container-fluid">
        <div class="row mobileFixedHeader primaryGradient navBarColorGradient">
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mobileHeadPadT" data-toggle="modal"
                data-target="#mobileSideBar">
                <i class="fa fa-bars mMenuToggle mT5 " aria-hidden="true"></i>
            </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 padL0 mobileHeadPadT" 
            data-toggle="modal" data-target="#mobileSideBar">
                <div class="headerWithBackArrow font-color">
                    <span  *ngIf="tab == 'About_Us'">About Us</span>
                    <span *ngIf="tab == 'Privacy_Policy'">Privacy Policy</span>
                    <span  *ngIf="tab == 'Terms_and_Conditions'">Terms and Condition</span>
                    <span  *ngIf="tab == 'Return_Policy'">Refund and Return Policy</span>
                    <span  *ngIf="tab == 'Shipping_Policy'">Shipping Policy</span>
                </div>
            </div>
        </div>
    </div>
</section>


<skeleton-loader *ngIf="isLoading"></skeleton-loader>
<section *ngIf="!isLoading">
    <div class="container-fluid mVTopMrg">
        <div class="privacyPolicyContainer" >
            <div [innerHTML]="description"></div>
        </div>
    </div>
</section>



