<!--Main Slider-->

<section id="mobileHeader" class="mobileView">
  <div class="container-fluid primaryGradient">
    <div class="row mobileFixedHeader primaryGradient navBarColorGradient">
      <div class="col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3 mobileHeadPadT" data-toggle="modal"
        data-target="#mobileSideBar">
        <i class="fa fa-bars mMenuToggle " aria-hidden="true"></i>
      </div>
      <div class="col-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 tCenter font-color mobileHeadPadT1">


        <div width="100%" height="auto" class="orgName font-color"
          *ngIf="orgnizationData && orgnizationData.isShowLogoOnWebsite">
          <img class="review-image-mobile" *ngIf="orgnizationData.websiteLogoFilePath"
            src="{{dataService.appConstant.IMAGE_URL}}{{orgnizationData.filePath}}" loading="lazy"
            onError="this.src='assets/img/errorLogo.png'">
        </div>
        <div class="d-flex" *ngIf="orgnizationData && !orgnizationData.isShowLogoOnWebsite">
          <div class="orgName1 font-color">{{orgnizationData.organizationName}}</div>
        </div>
        <span *ngIf="warehouseData && warehouseData.warehouseListCount > 1" class="warehouseName font-color"
          (click)="changeWare()">{{warehouseData.wareHouseName}}&nbsp;<i class="fa fa-pencil wareedit"></i></span>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3 tRight font-color mobileHeadPadT"
        routerLink="/web/ag/profile">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="w-100 d-flex">
          <input type="text" class="form-control1 " placeholder="What you want to buy...." [(ngModel)]="searchProduct"
            name="searchProduct" (ngModelChange)="clearSearch()">
          <div class="" type="button" (click)="searchProductByKey()">
            <span class="d-flex  btnpadding badge bg-dark text-white">
              <i class="fas fa-search  mx-1"></i>
              Search</span>
          </div>
        </div>
      </div>
      <div class="d-flex col-md-3">

        <a *ngIf="footerData && footerData.playStoreLink" href="{{footerData.playStoreLink}}">
          <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
        </a>
        <a *ngIf="footerData && footerData.appStoreLink" href="{{footerData.appStoreLink}}">
          <img class="appstore-icon" src="assets/img/AppStore.png" alt="Logo">
        </a>
      </div>
    </div>
  </div>
</section>
<a id="backToTopbuttonList"></a>

<body>
  <a id="backToTopbutton"></a>
  <skeleton-loader *ngIf="isLoading"></skeleton-loader>
  <div *ngFor="let section of homePageDataList;let homePageIndex=index">
    
   <!-- Slider DesktopView -->
    <section class="homePageSection desktopView" *ngIf="section.contentType=='1' && section.cmsSlider
    && section.cmsSlider.cmsSliderContentList && section.cmsSlider.cmsSliderContentList.length>0"
      (scroll)="onScrollOrder($event)" data-aos="zoom-in">
      <ng-container>
  
        <owl-carousel-o [options]="advertisingSlider">
          <ng-container *ngFor="let imgs of section.cmsSlider.cmsSliderContentList;let i=index">
            <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
              <div [ngClass]="{
                'sliderimage': section.cmsSlider.sliderWidth === 2,
                'sliderimage1': section.cmsSlider.sliderWidth === 1
              }" [ngStyle]="{ 'height.px': section.cmsSlider.height }">
                <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}" loading="lazy"
                  onError="this.src='assets/img/errorLogo.png'" (click)="onBannerClick(imgs)">
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container>
    </section>

     <!-- Slider Mobile View -->
    <section class="mainSlider mobileView animated fadeIn " id="mainSlider" *ngIf="section.contentType=='1' && section.cmsSlider
    && section.cmsSlider.cmsSliderContentList && section.cmsSlider.cmsSliderContentList.length>0" [ngClass]="{mobileViewMT: homePageIndex==0}">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div  (scroll)="onScrollOrder($event)">
              <owl-carousel-o [options]="advertisingSlider" >
                <ng-container *ngFor="let imgs of section.cmsSlider.cmsSliderContentList;let i=index">
                  <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                    <div>
                      <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}" loading="lazy"
                        onError="this.src='assets/img/errorLogo.png'" (click)="onBannerClick(imgs)">
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Top Categories For Desktop View -->
    <div data-aos="flip-right" class="container-fluid" *ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='3'
     && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0">
      <div>
        <section class="pt-5 mb-5 desktopView animated fadeIn"
          *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
          <div class="mx-1" *ngIf="pageSection.catList?.length > 0">
            <div class="row ">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                <div class="top-categories but-color font-color">
                  <h3>{{pageSection.title}}</h3>
                  <p>{{pageSection.description}}
                  </p>
                  <div class="but-color whiteBg ellipseBtn rectangleBannerBtn floatR" 
                    routerLink="/web/ag/categories">
                    <b> All Categories
                      &nbsp;&nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></b>
                  </div>
                  <br>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
  
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 product-category"
                    *ngFor="let category of pageSection.catList | slice:0:4" routerLink="/web/ag/products"
                    [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                    <div class="card-product but-color font-color">
                      <div class="categoryImage">
                        <img class="card-img-top"
                          src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-{{imgWidth}},h-{{imgHeight}},cm-pad_resize/{{category.filePath}}"
                          loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                      </div>
                      <div class="card-block-product  but-color font-color" routerLink="/web/ag/products"
                        [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                        <h5 class="text-bold-product font-color">{{category.name}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mt-3 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 product-category catCol"
                *ngFor="let category of pageSection.catList | slice:4">
                <div class="card-product but-color font-color" routerLink="/web/ag/products"
                  [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                  <div class="categoryImage">
                    <img class="card-img-top"
                      src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-{{imgWidth}},h-{{imgHeight}},cm-pad_resize/{{category.filePath}}"
                      loading="lazy" onError="this.src='assets/img/errorLogo.png'">
                  </div>
                  <div class="card-block-product">
                    <h5 class="text-bold-product font-color">{{category.name}} </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

     <!--Top Categories For Mobile View-->
    <div *ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='3'
     && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0">
    <section class="  mobileView animated fadeIn" [ngClass]="{mobileViewMT: homePageIndex==0}"
      *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList; let cmsSectionContentIndex = index;">
      <div class="container-fluid" *ngIf="pageSection.catList?.length > 0">
        <div class="row">
          <div class="col-12">
            <h3>{{ pageSection.title }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <ng-container *ngFor="let category of pageSection.catList; let i=index">
                <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3" style="padding: 3px;"
                  routerLink="/web/ag/products"
                  [queryParams]="{ category: category.name, category_uid: category.categoryUid, ecommPageUid: ecommPageUid }">
                  <div class="card-product1">
                    <div class="categoryImage1">
                      <img class="card-img-top1"
                        src="{{ dataService.appConstant.IMAGE_BASE_URL }}{{ category.filePath }}" loading="lazy"
                        onError="this.src='assets/img/errorLogo.png'">
                    </div>
                    <div class="card-block-product1" routerLink="/web/ag/products"
                      [queryParams]="{ category: category.name, category_uid: category.categoryUid, ecommPageUid: ecommPageUid }">
                      <h5 class="text-bold-product1">{{ category.name }}</h5>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


  
  <section  class="best-offer  animated fadeDown" id="apna-grocery" 
  *ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='1'
     && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0">
  <div  class="container-fluid" [ngClass]="{mobileViewPD: homePageIndex==0}">
    <div class="row">
      <div class="col-xl-12" >
        <ng-container>
          <div
            *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
            <h3 class="recent-product-title">{{ pageSection.title}} </h3>
            <span class="glyphicon glyphicon-adjust"></span>
           <div class="desktopView">
            <div *ngIf="pageSection  && pageSection.itemList.length < 5 ">
              <div class="row" >
                <div  class="col-xl-3 col-xxl-3" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                  <div class="card card-product-offer single-product">
                    <div class="card-img-top card-image" (click)="itemDetail(item)">
                      <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize" class="productListImage zoom"
                        src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" />
                      <img *ngIf="item.isManageImagecardSize && item.imageCardSize" class="productListImage zoom"
                        src="{{dataService.appConstant.IMAGE_BASE_URL}}{{item.imageCardSize}}/{{item.imageFilePath}}"
                        loading="lazy" />
                      <div class="product-offer"
                        *ngIf="item.discPerc && item.discountName && item.isMrpDiscount == false">
                        <p>{{item.discPerc}}% OFF</p>
                      </div>
                      <div class="product-offer"
                        *ngIf="item.isBuyXGetYDiscount && item.discountName && item.isMrpDiscount == false">
                        <p>{{item.discountName}}</p>
                      </div>
                      <div class="product-offer" *ngIf="!item.discountName && item.isMrpDiscount == true">
                        <p>{{currencySymbol}} {{item.discAmt}} OFF</p>
                      </div>
                    </div>
                    <div class="card-body card-item-details">
                      <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                        show-delay="100">{{item.brandName}}</div>
                      <h5 class="text-bold-all-product" [tooltip]="item.itemName" placement="top" show-delay="500"
                        (click)="itemDetail(item)">{{item.itemName}}</h5>
                      <div class="style-1">
                        <del>
                          <span *ngIf="item.discountName && item.isMrpDiscount == false && !item.isBuyXGetYDiscount"
                            class="discountprice">₹
                            {{item.withoutDiscAmount}}.00</span>
                          <span *ngIf="!item.discountName && item.isMrpDiscount == true && !item.isBuyXGetYDiscount"
                            class="discountprice">₹
                            {{item.mrp}}.00</span>
                        </del>
                        <ins>
                          <span *ngIf="item.isBuyXGetYDiscount" class="money">{{currencySymbol}}
                            {{item.withoutDiscAmount}}.00</span>
                          <span class="money" *ngIf="!item.isBuyXGetYDiscount">
                            {{currencySymbol}}
                            {{item.finalPrice}}.00</span>
                        </ins>
                      </div>
                      <div *ngIf="!item.isInventory || (item.isInventory && item.isStockAvailable)">
                        <div *ngIf="!checkAddToCard(item.itemUid)"
                          (click)="addToCartV1(item)"
                          class="addToCard but-color font-color">
                          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                          &nbsp;&nbsp; Add to cart
                          <!-- <div class="addToCartLoader" *ngIf="item.isLoading">
                            <img src="assets/img/spinner.gif">
                          </div> -->
                        </div>
                        <div class="cartCount" *ngIf="checkAddToCard(item.itemUid)">
                          <div class="flex alignCenter contentJustify">
                            <div class="but-color font-color incrementDecrement"
                              (click)="updateCartQuantityV1(item,'decrease')">
                              <i class="fa fa-angle-left" aria-hidden="true"></i>
                            </div>
                            <div class="itemCount"><b>{{getCartQty(item.itemUid)}}</b>
                            </div>
                            <div class="but-color font-color incrementDecrement"
                              (click)="updateCartQuantityV1(item,'increase')">
                              <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div *ngIf="pageSection  && pageSection.itemList.length > 4">
              <owl-carousel-o [options]="apnagrocerySlider" class="custom-carousel">
                <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                  <ng-template carouselSlide>
                    <div class="card card-product-offer single-product">
                      <div class="card-img-top card-image" (click)="itemDetail(item)">
                        <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize"
                          class="productListImage zoom"
                          src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" />
                        <img *ngIf="item.isManageImagecardSize && item.imageCardSize" class="productListImage zoom"
                          src="{{dataService.appConstant.IMAGE_BASE_URL}}{{item.imageCardSize}}/{{item.imageFilePath}}"
                          loading="lazy" />
                        <div class="product-offer"
                          *ngIf="item.discPerc && item.discountName && item.isMrpDiscount == false">
                          <p>{{item.discPerc}}% OFF</p>
                        </div>
                        <div class="product-offer"
                          *ngIf="item.isBuyXGetYDiscount && item.discountName && item.isMrpDiscount == false">
                          <p>{{item.discountName}}</p>
                        </div>
                        <div class="product-offer" *ngIf="!item.discountName && item.isMrpDiscount == true">
                          <p>{{currencySymbol}} {{item.discAmt}} OFF</p>
                        </div>
                      </div>
                      <div class="card-body card-item-details">
                        <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                          show-delay="100">{{item.brandName}}</div>
                        <h5 class="text-bold-all-product" [tooltip]="item.itemName" placement="top" show-delay="500"
                          (click)="itemDetail(item)">{{item.itemName}}</h5>
                        <div class="style-1">
                          <del>
                            <span
                              *ngIf="item.discountName && item.isMrpDiscount == false && !item.isBuyXGetYDiscount"
                              class="discountprice">₹
                              {{item.withoutDiscAmount}}.00</span>
                            <span
                              *ngIf="!item.discountName && item.isMrpDiscount == true && !item.isBuyXGetYDiscount"
                              class="discountprice">₹
                              {{item.mrp}}.00</span>
                          </del>
                          <ins>
                            <span *ngIf="item.isBuyXGetYDiscount" class="money">{{currencySymbol}}
                              {{item.withoutDiscAmount}}.00</span>
                            <span class="money" *ngIf="!item.isBuyXGetYDiscount">
                              {{currencySymbol}}
                              {{item.finalPrice}}.00</span>
                          </ins>
                        </div>
                        <div *ngIf="!item.isInventory || (item.isInventory && item.isStockAvailable)">
                          <div *ngIf="!checkAddToCard(item.itemUid)"
                            (click)="addToCartV1(item)"
                            class="addToCard but-color font-color">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                            &nbsp;&nbsp; Add to cart
                            <!-- <div class="addToCartLoader" *ngIf="item.isLoading">
                              <img src="assets/img/spinner.gif">
                            </div> -->
                          </div>
                          <div class="cartCount" *ngIf="checkAddToCard(item.itemUid)">
                            <div class="flex alignCenter contentJustify">
                              <div class="but-color font-color incrementDecrement"
                                (click)="updateCartQuantityV1(item,'decrease')">
                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                              </div>
                              <div class="itemCount"><b>{{getCartQty(item.itemUid)}}</b>
                              </div>
                              <div class="but-color font-color incrementDecrement"
                                (click)="updateCartQuantityV1(item,'increase')">
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
           </div>
           <div class="mobileView" [ngClass]="{mobileViewLastIndexMB: homePageDataList.length-1}">
            <div *ngIf="pageSection  && pageSection.itemList.length > 0">
              <owl-carousel-o [options]="apnagrocerySlider" class="custom-carousel">
                <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                  <ng-template carouselSlide>
                    <div class="card card-product-offer single-product">
                      <div class="card-img-top card-image" (click)="itemDetail(item)">
                        <img *ngIf="!item.isManageImagecardSize || !item.imageCardSize"
                          class="productListImage zoom"
                          src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" />
                        <img *ngIf="item.isManageImagecardSize && item.imageCardSize" class="productListImage zoom"
                          src="{{dataService.appConstant.IMAGE_BASE_URL}}{{item.imageCardSize}}/{{item.imageFilePath}}"
                          loading="lazy" />
                        <div class="product-offer"
                          *ngIf="item.discPerc && item.discountName && item.isMrpDiscount == false">
                          <p>{{item.discPerc}}% OFF</p>
                        </div>
                        <div class="product-offer"
                          *ngIf="item.isBuyXGetYDiscount && item.discountName && item.isMrpDiscount == false">
                          <p>{{item.discountName}}</p>
                        </div>
                        <div class="product-offer" *ngIf="!item.discountName && item.isMrpDiscount == true">
                          <p>{{currencySymbol}} {{item.discAmt}} OFF</p>
                        </div>
                      </div>
                      <div class="card-body card-item-details">
                        <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                          show-delay="100">{{item.brandName}}</div>
                        <h5 class="text-bold-all-product" [tooltip]="item.itemName" placement="top" show-delay="500"
                          (click)="itemDetail(item)">{{item.itemName}}</h5>
                        <div class="style-1">
                          <del>
                            <span
                              *ngIf="item.discountName && item.isMrpDiscount == false && !item.isBuyXGetYDiscount"
                              class="discountprice">₹
                              {{item.withoutDiscAmount}}.00</span>
                            <span
                              *ngIf="!item.discountName && item.isMrpDiscount == true && !item.isBuyXGetYDiscount"
                              class="discountprice">₹
                              {{item.mrp}}.00</span>
                          </del>
                          <ins>
                            <span *ngIf="item.isBuyXGetYDiscount" class="money">{{currencySymbol}}
                              {{item.withoutDiscAmount}}.00</span>
                            <span class="money" *ngIf="!item.isBuyXGetYDiscount">
                              {{currencySymbol}}
                              {{item.finalPrice}}.00</span>
                          </ins>
                        </div>
                        <div *ngIf="!item.isInventory || (item.isInventory && item.isStockAvailable)">
                          <div *ngIf="!checkAddToCard(item.itemUid)"
                            (click)="addToCartV1(item)"
                            class="addToCard but-color font-color">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                            &nbsp;&nbsp; Add to cart
                            <!-- <div class="addToCartLoader" *ngIf="item.isLoading">
                              <img src="assets/img/spinner.gif">
                            </div> -->
                          </div>
                          <div class="cartCount" *ngIf="checkAddToCard(item.itemUid)">
                            <div class="flex alignCenter contentJustify">
                              <div class="but-color font-color incrementDecrement"
                                (click)="updateCartQuantityV1(item,'decrease')">
                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                              </div>
                              <div class="itemCount"><b>{{getCartQty(item.itemUid)}}</b>
                              </div>
                              <div class="but-color font-color incrementDecrement"
                                (click)="updateCartQuantityV1(item,'increase')">
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
           </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>


<!--Brands-->
<div data-aos="flip-left" class="container-fluid"  [ngClass]="{mobileViewBD: homePageIndex==0}"
*ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='2'
     && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0">
<section class=" antimated fadeDown" id="special-offer-sec"
  *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
  <div class="recent-product-title" routerLink="/web/ag/shop-by-brand">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <h3>{{pageSection.title}}</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-end">
        <a>View All</a>
      </div>
    </div>
  </div>
  <div [ngClass]="{mobileViewLastIndexMB: homePageDataList.length-1}">
    <div *ngIf="pageSection.brandList">
      <div class="sliderrow row">
        <div size="4" *ngFor='let brand of pageSection.brandList'
          class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 desktopView">
          <div class="brandcard1" routerLink="/web/ag/products"
            [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
            <div class="brandimgdiv">
              <img
                src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{brand.filePath}}" o
                alt="" class="brandimg_mob center" nError="this.src='assets/img/errorLogo.png'">
            </div>
          </div>
        </div>
        <div size="4" *ngFor='let brand of pageSection.brandList'
          class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 changecolpad  mobileView">
          <div class="brandcard2" routerLink="/web/ag/products"
            [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
            <div class="brandimgdiv1">
              <img src="{{dataService.appConstant.IMAGE_BASE_URL}}{{brand.filePath}}" o alt=""
                class="brandimg_mob center" nError="this.src='assets/img/errorLogo.png'">
            </div>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mobileView">
          <div class=" brandcard3">
            <div class="brandimgdiv2" routerLink="/web/ag/shop-by-brand">
              <img src="assets/img/shop_by_view_all-arrow.svg" alt=""
                class="responsive center brandarrow brandimg_mob">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>

  <!-- Gallery Images-->

<section class="best-offer animated fadeDown" id="apna-grocery" *ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='8'
     && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0
     && section.cmsSectionBO.cmsSectionContentBOList[0].galleryList.length>0 && section.cmsSectionBO.cmsSectionContentBOList[0].galleryList[0].type==1">
  <div data-aos="zoom-in-down" [ngClass]="{mobileViewLastIndexMB: homePageDataList.length-1}">
    <div class="row">
      <div  class="col-xl-12">
        <ng-container >
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
              <h3 class="recent-product-title">{{section.title}}</h3>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 text-center spaceTop">

              <div
                *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">

                <div>
                  <owl-carousel-o [options]="gallerySlider">
                    <ng-container *ngFor="let item of pageSection.galleryList;let itemIndex=index">
                      <ng-container *ngFor="let galleryData of item.galleryDataBOList">
                        <ng-template carouselSlide>
                          <div class="card card-product-offer_gallery single-product">
                            <div class="card-img-top card-image_gallery img-wrapper">
                              <img class="productListImage1 zoom" id="galleryImg"
                                src="{{imageURL}}{{galleryData.path}}" loading="lazy" />
                            </div>
                          </div>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1 text-center"></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

  <!-- Gallery Videos-->

 
  <section class="container-fluid" id="apna-grocery"  *ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='8'
  && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0
  && section.cmsSectionBO.cmsSectionContentBOList[0].galleryList.length>0 && section.cmsSectionBO.cmsSectionContentBOList[0].galleryList[0].type==2">
    <div data-aos="fade-up" data-aos-duration="2000">
      <div class="row">
        <div  class="col-xl-12 ">
          <ng-container>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <h3 class="recent-product-title1">{{section.title}}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                <div
                  *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                  <div class="desktopView">
                  
                    <div *ngIf="pageSection.galleryList && pageSection.galleryList[0].galleryDataBOList.length<=3" class="row">
                      
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div class="row mx-1">
                          <div class="col-xl-3 px-1" *ngFor="let gallery of pageSection.galleryList[0].galleryDataBOList">
                            <div class=" ">
                              <div class="single-product1   p-1">
                                <iframe [src]="gallery.embeddedUrl" alt="Video Thumbnail"
                            class="frame" picture-in-picture></iframe>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="pageSection.galleryList && pageSection.galleryList[0].galleryDataBOList.length >3">
                      <owl-carousel-o [options]="galleryVideoSlider">
                        <ng-container *ngFor="let gallery of pageSection.galleryList[0].galleryDataBOList">
                          <ng-template carouselSlide class=" card card-block-product1">
                            <div class="single-product1   p-1">
                              <iframe [src]="gallery.embeddedUrl" alt="Video Thumbnail"
                            class="frame" picture-in-picture></iframe>
                            </div>

                          </ng-template>
                        </ng-container>
                      </owl-carousel-o>
                    </div>
                  </div> 
                  <div class="mobileView" [ngClass]="{mobileViewLastIndexMB: homePageDataList.length-1}" >
                    <owl-carousel-o [options]="galleryVideoSlider" *ngIf="pageSection.galleryList[0].galleryDataBOList">
                      <ng-container *ngFor="let gallery of pageSection.galleryList[0].galleryDataBOList">
                        <ng-template carouselSlide class=" card card-block-product1">
                          <div class="single-product1   p-1">
                            <iframe [src]="gallery.embeddedUrl" alt="Video Thumbnail"
                            class="frame" picture-in-picture></iframe>
                            <!-- <iframe [src]="gallery.path" alt="Video Thumbnail"  picture-in-picture class="frame"></iframe> -->
                          </div>

                        </ng-template>
                      </ng-container>
                    </owl-carousel-o>
                  </div>

                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>


  <section class="container-fluid"  id="apna-grocery"
  *ngIf="section.contentType=='2' && section.cmsSectionBO && section.cmsSectionBO.collection=='6'
     && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length>0">
  <div [ngClass]="{mobileViewPD: homePageIndex==0}">
    <div class="row">
      <div class="col-xl-12">
        <ng-container >
          <div class="row mt-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <h3 class="recent-product-title">{{section.title}}</h3>
            </div>
          </div>
          <div
            *ngFor="let pageSection of section.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
            <!-- <h3 class="recent-product-title">{{ pageSection.title}}
                    </h3> -->
            <div class="desktopView">
              <div *ngIf="pageSection && pageSection.testimonialList.length<=3" class="mt-3 mb-3 row">
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-xl-3 px-0" *ngFor="let item of pageSection.testimonialList;let itemIndex=index">
                      <!-- <ng-container *ngFor="let galleryData of item.galleryDataBOList"> -->
                      <div class="  card-block-product2">
                        <div class="single-product  services">
                          <div class=" content">
                            <small>{{item.createdDate | date:"MMM d, y"}}</small>
                            <h4 class="bold">{{item.createdBy}}</h4>
                            <div class="star-rating">
                              <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
                                <span class="star" [class.filled]="i <= item.rating">&#9733;</span>
                              </ng-container>
                            </div>
                            <!-- <div [ngClass]="{'pb-4': item.message.length < maxLength}" class="">

                              <p id="message" [ngClass]="{'show-less': showFullDescriptionMap[item.testimonialId]}">

                                {{ item.message}}
                              </p>
                              <div *ngIf="item.message.length > maxLength">
                                <a (click)="toggleDescription(item.testimonialId)" class="text-primary">
                                  {{ !showFullDescriptionMap[item.testimonialId] ? 'Read More':'Read Less' }}
                                </a>
                              </div>
                            </div> -->
                            <div class="container" style="padding: 0px;">
                              <div #contentReview class="note-field" [ngClass]="getClasses(item,contentReview)">
                                <span>{{ item.message }}</span>
                              </div>
                              <button style="border: none;" *ngIf="contentReview?.scrollHeight && contentReview.scrollHeight > 25"
                                class="show-button" (click)="item.show = !item.show">
                                {{ item.show ? 'Show less' : 'Show more' }}
                              </button>
                              <div *ngIf="contentReview?.scrollHeight && contentReview.scrollHeight < 25" style="margin-bottom: 5px;">&nbsp;&nbsp;
                              </div>
                            </div>
                            <!-- <p id="message" [innerHTML]="item.message" [class.showFull]="showFull"></p>
                                  <button *ngIf="!showFull" (click)="toggleReadMore()">Read more</button> -->
                          </div>
                          <!-- <img
                                  class="productListImage1 zoom"  id="galleryImg"
                                  src="{{imageURL}}{{galleryData.path}}"
                                  loading="lazy"
                                  /> -->
                          <!-- </div> -->
                        </div>

                      </div>
                      <!-- </ng-container> -->
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="pageSection && pageSection.testimonialList && pageSection.testimonialList.length>3" class="mt-3 mb-3">

                <owl-carousel-o [options]="gallerySlider">
                  <ng-container *ngFor="let item of pageSection.testimonialList;let itemIndex=index">
                    <!-- <ng-container *ngFor="let galleryData of item.galleryDataBOList"> -->
                    <ng-template carouselSlide class="card-block-product1">
                      <div class="single-product  services">
                        <div class=" content">
                          <small>{{item.createdDate | date:"MMM d, y"}}</small>
                          <h4 class="bold">{{item.createdBy}}</h4>
                          <div class="star-rating">
                            <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
                              <span class="star" [class.filled]="i <= item.rating">&#9733;</span>
                            </ng-container>
                          </div>
                          <!-- <div [ngClass]="{'pb-4': item.message.length < maxLength}" class="">

                            <h6  id="message" [ngClass]="{'show-less': showFullDescriptionMap[item.testimonialId]}">
                              {{ item.message}}
                            </h6>
                            <div *ngIf="item.message.length > maxLength">
                              <a (click)="toggleDescription(item.testimonialId)" class="text-primary">
                                {{ !showFullDescriptionMap[item.testimonialId] ? 'Read More':'Read Less' }}
                              </a>
                            </div>
                          </div> -->

                          <div #contentReview class="container" style="padding: 0px;">
                            <div  class="note-field" [ngClass]="getClasses(item,contentReview)">
                              <span>{{ item.message }}</span>
                            </div>
                            <button style="border: none;" *ngIf="contentReview?.scrollHeight && contentReview.scrollHeight > 25"
                              class="show-button" (click)="item.show = !item.show">
                              {{ item.show ? 'Show less' : 'Show more' }}
                            </button>
                            <div *ngIf="contentReview?.scrollHeight && contentReview.scrollHeight < 25" style="margin-bottom: 5px;">&nbsp;&nbsp;
                            </div>
                          </div>


                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
            <div class="mobileView"  [ngClass]="{mobileViewLastIndexMB: homePageDataList.length-1}">
              <div class="mt-3">
                <owl-carousel-o [options]="gallerySlider">
                  <ng-container *ngFor="let item of pageSection.testimonialList;let itemIndex=index">
                    <!-- <ng-container *ngFor="let galleryData of item.galleryDataBOList"> -->
                    <ng-template carouselSlide class="card-block-product1">
                      <div class="single-product  services">
                        <div class=" content">
                          <small>{{item.createdDate | date:"MMM d, y"}}</small>
                          <h4 class="bold">{{item.createdBy}}</h4>
                          <div class="star-rating">
                            <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
                              <span class="star" [class.filled]="i <= item.rating">&#9733;</span>
                            </ng-container>
                          </div>
                          <!-- <div [ngClass]="{'pb-4': item.message.length < mobilemaxLength}" class="">

                            <p id="message" [ngClass]="{'show-less': showFullDescriptionMap[item.testimonialId]}">

                              {{ item.message}}
                            </p>
                            <div *ngIf="item.message.length > mobilemaxLength">
                              <a (click)="toggleDescription(item.testimonialId)" class="text-primary">
                                {{ !showFullDescriptionMap[item.testimonialId] ? 'Read More':'Read Less' }}
                              </a>
                            </div>
                          </div> -->
                          <div class="container" style="padding: 0px;">
                            <div #contentReview class="note-field" [ngClass]="getClasses(item,contentReview)">
                              <span>{{ item.message }}</span>
                            </div>
                            <button style="border: none;" *ngIf="contentReview?.scrollHeight && contentReview.scrollHeight > 25"
                              class="show-button" (click)="item.show = !item.show">
                              {{ item.show ? 'Show less' : 'Show more' }}
                            </button>
                            <div *ngIf="contentReview?.scrollHeight && contentReview.scrollHeight < 25" style="margin-bottom: 5px;">&nbsp;&nbsp;
                            </div>
                          </div>
                          <!-- <p id="message" [innerHTML]="item.message" [class.showFull]="showFull"></p>
                                        <button *ngIf="!showFull" (click)="toggleReadMore()">Read more</button> -->
                        </div>
                        <!-- <img
                                        class="productListImage1 zoom"  id="galleryImg"
                                        src="{{imageURL}}{{galleryData.path}}"
                                        loading="lazy"
                                        /> -->
                        <!-- </div> -->
                      </div>

                    </ng-template>
                    <!-- </ng-container> -->
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>


  </div>
</body>