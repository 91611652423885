import { Component, OnInit, HostListener, PLATFORM_ID, Inject, NgZone, Pipe, PipeTransform, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { AppConstantsService } from 'src/app/constants/app-constants.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { HomePageState } from 'src/app/store/state/homepage.state';
import { getHomePageLists, getPageId, getPageWithSeqNo } from 'src/app/store/actions/homepage.action';
import AOS from "aos";
declare var $: any;
declare var google;

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss']
})

export class NewHomeComponent implements OnInit {

  @Select(HomePageState.getPageListData) pageList$: Observable<any[]>;

  @Select(HomePageState.getPageListLoaded) pageListLoaded$: Observable<boolean>;

  pageListloadedSub: Subscription;

  @Select(HomePageState.getPageListByIdData) pageListById$: Observable<any[]>;

  @Select(HomePageState.getPageListByIdLoaded) pageListByIdLoaded$: Observable<boolean>;

  pageListByIdloadedSub: Subscription;

  // page seq No
  @Select(HomePageState.getPageWithSeqNoData) pageWithSeqNo$: Observable<any[]>;

  @Select(HomePageState.getPageWithSeqNoLoaded) pageWithSeqNoLoaded$: Observable<boolean>;

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  embeddedUrl: SafeResourceUrl;
  show = true;
  show2 = true;
  show3 = true;
  data1 = {
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    note2: 'Lorem ipsum',
  };

  pageWithSeqNoloadedSub: Subscription;
  public websiteProduct: any = {};
  public user: any = {};
  public websiteProductList: any = {};
  public userUniqueId;
  public productListIndex: any;
  public cmsSectionContentIndex: any;
  public itemIndex: any;
  public cartList: any = [];
  public proceedToAdd: any = {};
  public sequenceList: any = [];
  public seqNoIndex: any = 0;
  public homePageDataList: any = [];
  public pagination: any = { seqnoList: [] }
  public isMobileView: boolean = false;
  public isHomeSectionLoading: boolean = false;
  public skeletonSection: any = [{}, {}, {}]
  public homePage: any;
  public data: any;
  public previewDataList: any = [];
  public freshPreviewDataList: any = []
  public getPageList: any = [];
  public currencySymbol: any;
  public mainSlider: any = {};
  public topCategoryView: any = {};
  public topBrandView: any = {};
  public topProductView: any = {};
  public bestSellerProduct: any = {};
  public bestSellerProductList: any = {};
  public recommendationView: any = {};
  public recentlyView: any = {};
  public frequentlyView: any = {};
  public galleryView: any = {};
  public videosView: any = {};
  public galleryVidList: any = [];
  subSlider2: any = {};
  bannerSection1: any = {};
  bannerSection2: any = {};
  bannerSection3: any = {};
  bannerSection4: any = {};
  ecommPageUid: any;
  bannerSection5: any = {};
  bannerSection6: any = {};
  bannerSection7: any = {};
  bannerSection8: any = {};
  bannerSection9: any = {};
  bannerSection10: any = {};
  bannerSection11: any = {};
  bannerSection12: any = {};
  bannerSection13: any = {};
  bannerSection14: any = {};
  bannerSection15: any = {};
  bannerSection16: any = {};
  bannerSection17: any = {};
  bannerSection18: any = {};
  public footerData: any = {};
  public isSearchLoading: boolean = false;
  public productList: any = [];
  public freshProductList: any = [];
  public selectedVariantIndex: any;
  public couponList: any = [];
  public isProductLoading: boolean = false;
  public onScrollLoading: boolean = false;
  public isSearchProductLoading: boolean = false;
  productCount: any;
  imgWidth: number;
  imgHeight: number;
  //LOGIN
  public isLoadMore: boolean = true;
  public isLoading: boolean = false;
  public isServerError: boolean = false;
  public loginTab: any;
  public isLoginOtpRequested: boolean = false;
  public signUpInPopup: any;
  public isInstanceLogin: boolean = false;
  public itemLoader: boolean = false;
  public isCategoryLoading: boolean = false;
  public searchProduct: any;
  public whatsappLink: String = '';
  public paginationProd: any = { pageNum: 1, numPerPage: 10 }
  carouselOptions = {
    items: 4,
    loop: false,
    dots: false,
    nav: true,
    autoplay: true,
    navText: ['<span class="custom-nav-button"><i class="fa fa-chevron-left"></i></span>', // Previous button
      '<span class="custom-nav-button mx-3"><i class="fa fa-chevron-right"></i></span>' // Next button]
    ],
    responsive: {
      0: {
        items: 2
      },
      576: {
        items: 2
      },
      768: {
        items: 2
      },
      992: {
        items: 4
      }
    }
  };


  // carouselOptions1 = {
  //   items: 3,
  //   loop: true,
  //   nav: true,
  //   dots: false,
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     768: {
  //       items: 3
  //     }
  //   }
  // };

  advertisingSlider: OwlOptions = {
    loop: true,
    items: 1,
    dots: true,
    navSpeed: 500,
    // smartSpeed: 5000,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false, autoplay: true,
    autoplayTimeout: 3000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    // animateOut: 'slideOutDown',
    // animateIn: 'flipInX',
    // animateOut: 'bounceOut ',
    // animateIn: 'bounceIn',
    autoplayHoverPause: true
  };


  // bestofferSlider: OwlOptions = {
  //   loop: false,
  //   items: 4,
  //   autoWidth: true,
  //   mouseDrag: true,
  //   autoplay: false,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: false,
  //   navSpeed: 700,
  //   navText:['<span class="custom-nav-button"><i class="fa fa-chevron-left"></i></span>', // Previous button
  //   '<span class="custom-nav-button mx-3"><i class="fa fa-chevron-right"></i></span>' // Next button]
  // ],
  //   responsive: {
  //     0: {
  //       items: 1.2
  //     },
  //     400: {
  //       items: 3
  //     },
  //     740: {
  //       items: 4
  //     },

  //   },

  // };


  orgnizationData: any = {};
  public warehouseData: any = {};
  imageURL: string;
  galleryList: any = [];
  currentVideoIndex: number = 0;
  testimonialView: any = {};
  testimonialList: any = []
  maxLength = 50;
  mobilemaxLength = 23;
  showFullMessageMap: { [key: number]: boolean } = {};
  showFullDescriptionMap: { [key: number]: boolean } = {};
  sanitizedVideoUrls: SafeResourceUrl[] = [];
  constructor(
    public dataService: CommanservicesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public constant: AppConstantsService,
    public router: Router,
    private store: Store,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private zone: NgZone,
    protected sanitizer: DomSanitizer,
  ) {

    this.imageURL = this.constant.IMAGE_URL;
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.sendMessage('pageChange')
      sessionStorage.setItem('pageName', "HomePage")
      this.dataService.sendMessage('clearSearchBar')
      this.user = JSON.parse(localStorage.getItem("userInfo") || "{}");
      this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
      if (this.user) {
        this.userUniqueId = this.user.userUniqueId
        this.cartList = JSON.parse(localStorage.getItem("cartList") || "{}");
      }
      this.currencySymbol = this.dataService.appConstant.getCurrencySymbol();

      this.dataService.getMessage().subscribe((msg) => {
        if (msg === "LoggedIn") {
          this.user = JSON.parse(localStorage.getItem("userInfo") || "{}");
        }
        if (msg === "HomePage") {
          this.productListIndex = sessionStorage.getItem('productListIndex');
          this.cmsSectionContentIndex = sessionStorage.getItem('cmsSectionContentIndex');
          this.itemIndex = sessionStorage.getItem('itemIndex');

          this.addToCart(this.productListIndex, this.cmsSectionContentIndex, this.itemIndex)
          sessionStorage.removeItem('pageName');
          sessionStorage.removeItem('productListIndex');
          sessionStorage.removeItem('cmsSectionContentIndex');
          sessionStorage.removeItem('itemIndex');
        }
        if (msg == 'cartListUpdated') {
          this.cartList = JSON.parse(localStorage.getItem("cartList") || "{}");
          this.updateProductList();
        }
        if (msg == 'showLoader') {
          this.isLoading = true
        }
        if (msg == 'hideLoader') {
          this.isLoading = false
        }
        if (msg == 'hideMoreLoader') {
          this.itemLoader = false;
        }
        if (msg == 'Selected_Warehouse') {
          this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
          if (this.warehouseData) {
            this.paginationProd.warehouseId = this.warehouseData.warehouseId;
            window.location.reload();
          }
        }
        if (msg == 'logged_out') {
          this.loadAllMethods();
        }

      });

    }
  }
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit(): void {
    AOS.init();
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 568) {
        this.imgWidth = 100;
        this.imgHeight = 100;
      } else {
        this.imgWidth = 300;
        this.imgHeight = 300;
      }

      var btn = $('#backToTopbutton');

      $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
          btn.addClass('show');
        } else {
          btn.removeClass('show');
        }
      });

      btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
      });
    }
    this.dataService.getDomainWiseOrgnization();
    this.orgnizationData = this.dataService.currentMessage.subscribe(message => this.orgnizationData = message);

    if (this.orgnizationData && this.orgnizationData.mobile) {
      this.whatsappLink = 'https://api.whatsapp.com/send/?phone=%2B91' + this.orgnizationData.mobile + '&text&type=phone_number&app_absent=0';

    }
    this.loadAllMethods();
    this.getFooterData();

  }
  toggleDescription(id: number) {
    this.showFullDescriptionMap[id] = !this.showFullDescriptionMap[id];
  }
  getClasses(item, contentReview) {
    const classes = {
      'show': item && item.show,
      'note-text': contentReview && contentReview.scrollHeight > 25
    };
    return classes;
  }
  // Redirect To
  redirectToSearch(searchProduct) {
    if (searchProduct && searchProduct.length > 2) {
      this.router.navigate(['/web/ag/products'], { queryParams: { tag: searchProduct } })
    }
  }

  searchProductByKey() {
    if (this.searchProduct.length > 2 || this.searchProduct == '') {
      this.paginationProd.pageNum = 1;
      this.paginationProd.searchKey = this.searchProduct;
      // this.getSearchedItem();
      if (!this.searchProduct) {
        this.router.navigate(['/web/ag/products']);
      }
    }
  }
  public getSearchedItem(): void {
    if (this.user) {
      this.paginationProd.userUid = this.user.userUniqueId;
    }
    this.paginationProd.popularity = true,
      this.paginationProd.sortType = "popularity"
    this.isLoadMore = true;
    this.paginationProd.pageNum = 1;
    this.isSearchLoading = true;
    if (this.productCount) {
      this.paginationProd.listCount = this.productCount;
    }
    this.dataService.openPostRequest(this.paginationProd, this.dataService.appConstant.SERVER_URLS['GET_SINGLE_ITEM']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.freshProductList = [];
        this.productList = [];
        this.freshProductList = this.data.result;
        this.productList = this.data.result;
        this.productCount = this.data.listCount;

        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        if (this.user && this.cartList && this.cartList.length > 0) {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                this.cartList.forEach((element3: any) => {
                  if (element3.itemUid == element2.itemUid) {
                    element2.isAdded = true
                    element2.quantity = element3.quantity;
                    element2.cartId = element3.cartId;
                  }
                });
              });
            }

            //IF PRODUCT NOT HAVE ITEM GROUP ;
            //  if (!element.otherChildItemsBOList) {
            element.isAdded = false;
            element.quantity = 1;
            this.cartList.forEach((element3: any) => {
              if (element3.itemUid == element.itemUid) {
                element.isAdded = true
                element.quantity = element3.quantity;
                element.cartId = element3.cartId;
              }
            });
            ///  }

          });
        } else {
          this.productList.forEach((element: any) => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.otherChildItemsBOList.forEach((element2: any) => {
                element2.isAdded = false
                element2.quantity = 1;
              });
            }
          });
        }

        //SELECT DEFAULT VARIANT OF ITEM;
        this.productList.forEach((element: any) => {
          if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
            element.selectedItem = element.otherChildItemsBOList[0];
            element.selectedItem.selectedVariantIndex = 0
            if (!element.selectedItem.quantity || element.selectedItem.quantity <= 0) {
              element.selectedItem.quantity = 1
            }
          }
        });




        this.isLoading = false;
        this.isSearchLoading = false;
      } else {
        this.isLoading = false;
        this.isSearchLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isSearchLoading = false;
      this.dataService.throwError(err)
    })
  }
  clearSearch() {
    if (this.searchProduct.length > 2) {
      this.paginationProd.pageNum = 1;
      this.paginationProd.searchKey = this.searchProduct;
      this.router.navigate(['/web/ag/search'], { queryParams: { tag: this.searchProduct } });
      this.getItems();
    }
  }
  getItems() {
    throw new Error('Method not implemented.');
  }
  public getItemsWithScroll(isOnscroll) {
    this.onScrollLoading = true;
    if (this.productCount) {
      this.paginationProd.listCount = this.productCount;
    } else {
      this.paginationProd.listCount = null;
    }
    this.dataService.openPostRequest(this.paginationProd, this.dataService.appConstant.SERVER_URLS['GET_SINGLE_ITEM']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {

        if (this.data.result && this.data.result.length > 0) {

          if (!isOnscroll) {
            this.freshProductList = [];
            this.productList = [];
          }

          this.data.result.forEach(element => {
            this.productList.push(element);
          });
          this.data.result.forEach(element => {
            this.freshProductList.push(element)
          });

          this.productCount = this.data.listCount;
          // this.data.result.forEach(element => {
          //   this.freshProductList.push(element)
          //   this.productList.push(element)
          // });




          //CHECK ITEM ALREADY ADDED INTO CART OR NOT
          if (this.user && this.cartList && this.cartList.length > 0) {
            this.productList.forEach(element => {
              if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
                element.otherChildItemsBOList.forEach(element2 => {
                  this.cartList.forEach(element3 => {
                    if (element3.itemUid == element2.itemUid) {
                      element2.isAdded = true
                      element2.quantity = element3.quantity;
                      element2.cartId = element3.cartId;
                    }
                  });
                });
              }

              //IF PRODUCT NOT HAVE ITEM GROUP ;
              // if(!element.otherChildItemsBOList){
              element.isAdded = false;
              element.quantity = 1;
              this.cartList.forEach(element3 => {
                if (element3.itemUid == element.itemUid) {
                  element.isAdded = true
                  element.quantity = element3.quantity;
                  element.cartId = element3.cartId;
                }
              });
              //      }

            });
          } else {
            this.productList.forEach(element => {
              if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
                element.otherChildItemsBOList.forEach(element2 => {
                  element2.isAdded = false
                  element2.quantity = 1;
                });
              }
            });
          }

          //SELECT DEFAULT VARIANT OF ITEM;
          this.productList.forEach(element => {
            if (element.otherChildItemsBOList && element.otherChildItemsBOList.length > 0) {
              element.selectedItem = element.otherChildItemsBOList[0];
              element.selectedItem.selectedVariantIndex = 0
              if (!element.selectedItem.quantity || element.selectedItem.quantity <= 0) {
                element.selectedItem.quantity = 1
              }
            }
          });


        } else {
          this.isLoadMore = false
        }

        this.onScrollLoading = false;
      } else {
        this.onScrollLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.dataService.throwError(err)
    })
  }


  itemDetail(item) {
    // if (item.itemLink) {
    //   this.router.navigate(['/web/ag/product', item.itemLink]);
    // } else {
    this.router.navigate(['web/product-detail'], { queryParams: { itemUid: item.itemUid } });
    // }
  }
  loadAllMethods() {
    this.getOrganization();
    this.getHomePageList();
    // this.getSeqList();
    if (!this.pageWithSeqNo$) {
      this.isLoading = true
    }
  }
  //GET ORGANIZATION
  getOrganization() {
    this.dataService.getOrganization(this.dataService.appConstant.SERVER_URLS['GET_ORGANIZATION']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === result.status) {
        localStorage.setItem("primary_organization", JSON.stringify(this.data.result))
      }
    }, err => {
      this.dataService.throwError(err)
      // this.isLoading = false;
    })
  }
  getFooterData() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_FOOTER_DATA"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.footerData = this.data.result;
      }
    }, err => {
    })
  }
  getHomePageList() {
    // this.isLoading = true;
    this.pageListloadedSub = this.pageListLoaded$.subscribe(loadedBrands => {
      if (!loadedBrands) {
        this.store.dispatch(new getHomePageLists());
        // this.isLoading = false;
      }
    })
    this.pageList$.subscribe(result => {
      this.data = result;
      // this.isLoading = false;
      this.getPageList = result;
      this.getPageList.forEach((element: any) => {
        if (element.ecommPageName == 'Home Page') {
          this.title.setTitle(element.metaTitle);
          this.meta.updateTag({ name: 'description', content: element.metaDescription });
          // this.title.setTitle("Food Junction World Food Gourmet Store and Online Supermarket in Pune");
          // this.meta.updateTag({ name: 'description', content: "An online grocer is either a brick-and-mortar supermarket or grocery store that allows online ordering, or a standalone e-commerce service that includes grocery items. ... The online ordering is done through e-commerce websites or mobile apps." });
          this.homePage = element;



          this.title.setTitle(element.metaDescription);
          // this.meta.updateTag({ name: 'description', content: "An online grocer is either a brick-and-mortar supermarket or grocery store that allows online ordering, or a standalone e-commerce service that includes grocery items. ... The online ordering is done through e-commerce websites or mobile apps." });
          this.getSeqList();
        }
        if (element.ecommPageName == 'List Page') {
          this.ecommPageUid = element.ecommPageUid;
        }
      });
    })

    // this.isLoading = true;
    // this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['ECOMM_PAGE_LIST']).subscribe(result => {
    //   this.data = result;
    //   if (this.dataService.appConstant.SUCCESS === result.status) {
    //     this.getPageList = this.data.result;
    //     this.getPageList.forEach((element: any) => {
    //       if (element.ecommPageName == 'Home Page') {
    //         this.homePage = element;
    //       }
    //     });
    //     setTimeout(() => {
    //       this.getSeqList();
    //     }, 1000)
    //   } else {
    //     this.isLoading = false;
    //   }
    // }, err => {
    //   this.dataService.throwError(err)
    // })
  }
  getPageBySeqNoWithoutLoader() {
    this.isHomeSectionLoading = true;
    this.pagination.ecommPageUid = this.homePage.ecommPageUid;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    // this.pageWithSeqNoloadedSub = this.pageWithSeqNoLoaded$.subscribe(loadedBySeq => {
    // if (!loadedBySeq) {
    // this.store.dispatch(new getPageWithSeqNo(this.pagination));
    // this.isLoading = false;
    // }
    // })
    // this.pageWithSeqNo$.subscribe(result => {
    //   this.data = result;
    // })
    this.dataService.openPostRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['GET_PAGE_BY_SEQ']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === result.status) {
        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        this.data.result.forEach((element: any) => {
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
            element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
              if (element1.itemList && element1.itemList.length > 0) {
                element1.itemList.forEach((item: any) => {
                  item.isAdded = false
                  item.quantity = 1;
                });
              }
            });
          }
        });


        // this.data.result.forEach(element => {
        //   this.freshPreviewDataList.push(element)
        // });

        let isPushes = false;
        this.data.result.forEach(element => {
          isPushes = false;
          this.previewDataList.forEach(element1 => {
            if (element1.seqNo == element.seqNo) {
              isPushes = true
            }
          });
          if (!isPushes) {
            if (!element.showOnlyLoginUser) {
              this.previewDataList.push(element)
            } else {
              if (this.user) {
                this.previewDataList.push(element)
              }
            }
          }
        });

        this.freshPreviewDataList = this.previewDataList;
        this.previewDataList.forEach((element, key) => {
          element.previewListIndex = key
          //DONE
          if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS7188529240') {
            if (!element.showOnlyLoginUser) {
              this.mainSlider = element
            } else {
              if (this.user.userUniqueId) {
                this.mainSlider = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH0502266720') {
            if (!element.showOnlyLoginUser) {
              this.topCategoryView = element
            } else {
              if (this.user.userUniqueId) {
                this.topCategoryView = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3859218777') {
            if (!element.showOnlyLoginUser) {
              this.topBrandView = element
            } else {
              if (this.user.userUniqueId) {
                this.topBrandView = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH4784391729') {
            if (!element.showOnlyLoginUser) {
              this.topProductView = element
            } else {
              if (this.user.userUniqueId) {
                this.topProductView = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'PGC6310078085') {
            if (!element.showOnlyLoginUser) {
              this.bestSellerProduct = element
            } else {
              if (this.user.userUniqueId) {
                this.bestSellerProduct = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH5387819003') {
            if (!element.showOnlyLoginUser) {
              this.websiteProduct = element
            } else {
              if (this.user.userUniqueId) {
                this.websiteProduct = element
              }
            }
          }

          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH7173940210') {
            if (!element.showOnlyLoginUser) {
              this.recommendationView = element
            } else {
              if (this.user.userUniqueId) {
                this.recommendationView = element
              }
            }
          }

          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3057948827') {
            if (!element.showOnlyLoginUser) {
              this.recentlyView = element
            } else {
              if (this.user.userUniqueId) {
                this.recentlyView = element
              }
            }
          }

          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH2092496496') {
            if (!element.showOnlyLoginUser) {
              this.frequentlyView = element
            } else {
              if (this.user.userUniqueId) {
                this.frequentlyView = element
              }
            }
          }
          console.log("----------------------");
          if (element.cmsSectionBO && element.cmsSectionBO.collection == '8') {
            console.log("----1111");

            if (element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
              console.log("----22222");
              if (element.cmsSectionBO.cmsSectionContentBOList[0].galleryList && element.cmsSectionBO.cmsSectionContentBOList[0].galleryList.length > 0) {
                console.log("----3333");
                if (element.cmsSectionBO.cmsSectionContentBOList[0].galleryList[0]) {
                  console.log("----4444");
                  if (element.cmsSectionBO.cmsSectionContentBOList[0].galleryList[0].type == 1) {
                    this.galleryView = element
                  } else {
                    this.videosView = element
                    // this.galleryVidList = this.videosView.galleryDataBOList;
                    // if (this.videosView.type == 2 && this.galleryVidList && this.galleryVidList.length > 0) {
                    //   console.log("----4444->"+"converttedd");
                    //   this.galleryVidList = this.galleryVidList.map(video => ({
                    //     ...video,
                    //     path: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.getYouTubeVideoId(video.path))
                    //   }));

                    // }
                  }
                }
              }
            }
          }

          if (element.cmsSectionBO && element.cmsSectionBO.collection == '8') {
            console.log("----1111");

            if (element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
              console.log("----22222");
              if (element.cmsSectionBO.cmsSectionContentBOList[0].testimonialList && element.cmsSectionBO.cmsSectionContentBOList[0].testimonialList.length > 0) {
                console.log("----3333");
                if (element.cmsSectionBO.cmsSectionContentBOList[0].testimonialList) {

                  this.testimonialView = element

                  // this.galleryVidList = this.videosView.galleryDataBOList;
                  // if (this.videosView.type == 2 && this.galleryVidList && this.galleryVidList.length > 0) {
                  //   console.log("----4444->"+"converttedd");
                  //   this.galleryVidList = this.galleryVidList.map(video => ({
                  //     ...video,
                  //     path: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.getYouTubeVideoId(video.path))
                  //   }));

                  // }

                }
              }
            }
          }

          //DONE
          if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {
            if (!element.showOnlyLoginUser) {
              this.subSlider2 = element
            } else {
              if (this.user.userUniqueId) {
                this.subSlider2 = element
              }
            }
          }
        });


        if (this.user && this.cartList && this.cartList.length > 0) {
          this.previewDataList.forEach((element: any) => {
            if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
              element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
                if (element1.itemList && element1.itemList.length > 0) {
                  element1.itemList.forEach((item: any) => {
                    this.cartList.forEach((cartItem: any) => {
                      if (item.itemUid == cartItem.itemUid) {
                        item.isAdded = true
                        item.quantity = cartItem.quantity;
                        item.cartId = cartItem.cartId;
                      }
                    });
                  });
                }
              });
            }
          });
        }
        this.isHomeSectionLoading = false;

      } else {
        this.isHomeSectionLoading = false;
      }
    }, err => {
      this.isHomeSectionLoading = false;
      this.dataService.throwError(err)
    })

  }


 

  getPagePreviewList() {
    // this.isLoading = true;
    this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['GET_PAGE_PREVIEW_LIST'] + this.homePage.ecommPageUid + "/" + this.userUniqueId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        // this.isLoading = false;

        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        this.data.result.forEach((element: any) => {
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
            element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
              if (element1.itemList && element1.itemList.length > 0) {
                element1.itemList.forEach((item: any) => {
                  item.isAdded = false
                  item.quantity = 1;
                });
              }
            });
          }
        });


        this.freshPreviewDataList = this.data.result;
        this.previewDataList = this.data.result;



        this.previewDataList.forEach((element, key) => {
          element.previewListIndex = key



          //DONE
          if (element.pageContentUid == 'PGC2725171252') {
            this.mainSlider = element
          }
          //DONE
          if (element.pageContentUid == 'PGC7874776730') {
            this.topCategoryView = element
          }
          //DONE
          if (element.pageContentUid == 'PGC2725171253') {
            this.topBrandView = element
          }
          //DONE
          if (element.pageContentUid == 'PGC2725171254') {
            this.topProductView = element
          }


          //DONE
          if (element.pageContentUid == 'PGC2725171255') {
            this.bestSellerProduct = element
          }


          //DONE
          if (element.pageContentUid == 'PGC2725171256') {
            this.websiteProduct = element
          }

          if (element.pageContentUid == 'PGC3966040336') {
            this.recommendationView = element
          }

          //DONE
          if (element.pageContentUid == 'PGC2725171257') {
            this.recentlyView = element
          }

          if (element.pageContentUid == 'PGC1875104249') {
            this.frequentlyView = element
          }

          if (element.pageContentUid == 'PGC3377290824') {
            this.galleryView = element
          }

          if (element.pageContentUid == 'PGC2294471319') {
            this.testimonialView = element
          }

          //DONE
          if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {
            this.subSlider2 = element
          }


        });



        if (this.user && this.cartList && this.cartList.length > 0) {
          this.previewDataList.forEach((element: any) => {
            if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
              element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
                if (element1.itemList && element1.itemList.length > 0) {
                  element1.itemList.forEach((item: any) => {
                    this.cartList.forEach((cartItem: any) => {
                      if (item.itemUid == cartItem.itemUid) {
                        item.isAdded = true
                        item.quantity = cartItem.quantity;
                        item.cartId = cartItem.cartId;
                      }
                    });
                  });
                }
              });
            }
          });
        }
      } else {
        // this.isLoading = false;
      }
    }, err => {
      this.dataService.throwError(err)
    })
  }



  getSeqList() {
    // this.isLoading = true;
    this.pageListByIdloadedSub = this.pageListByIdLoaded$.subscribe(loadedById => {
      if (!loadedById) {
        this.store.dispatch(new getPageId(this.homePage.ecommPageUid));
        // this.isLoading = false;
      }
    })
    this.pageListById$.subscribe(result => {
      this.data = result;
      // this.isLoading = false;
      this.sequenceList = result;
      if (this.sequenceList && this.sequenceList.length > 0) {
        // this.sequenceList.forEach(element => {
        //   this.pagination.seqnoList.push(element.seqNo)
        // });
        this.pagination.seqnoList.push(this.sequenceList[0].seqNo)
        this.pagination.seqnoList.push(this.sequenceList[1].seqNo)
        this.seqNoIndex = 2;
        this.getPageBySeqNo()
      }
      // setTimeout(() => {
      //   this.getPageBySeqNo()
      // }, 200);
    })
    // this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['GET_SEQ_OF_PAGE'] + "/" + this.homePage.ecommPageUid).subscribe(result => {
    //   this.data = result;
    //   if (this.dataService.appConstant.SUCCESS === result.status) {
    //     this.sequenceList = this.data.result;
    //     this.pagination.seqnoList.push(this.sequenceList[0].seqNo)
    //     this.pagination.seqnoList.push(this.sequenceList[1].seqNo)
    //     this.seqNoIndex = 2
    //     this.getPageBySeqNo()
    //   }
    // }, err => {
    //   this.dataService.throwError(err)
    //   this.isLoading = false;
    // })
  }


  getPageBySeqNo() {
    this.itemLoader = true;
    this.pagination.ecommPageUid = this.homePage.ecommPageUid;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    this.pageWithSeqNoloadedSub = this.pageWithSeqNoLoaded$.subscribe(loadedBySeq => {
      if (!loadedBySeq) {
        this.store.dispatch(new getPageWithSeqNo(this.pagination));
      }
    })
    this.pageWithSeqNo$.subscribe(result => {



      // this.itemLoader = true;
      this.data = result;
      if (result) {
        if (result && result.length > 0) {
          result.forEach(section => {
            if (section.contentType == '2' && section.cmsSectionBO && section.cmsSectionBO.collection == '8'
              && section.cmsSectionBO.cmsSectionContentBOList && section.cmsSectionBO.cmsSectionContentBOList.length > 0
              && section.cmsSectionBO.cmsSectionContentBOList[0].galleryList.length > 0 && section.cmsSectionBO.cmsSectionContentBOList[0].galleryList[0].type == 2) {
              section.cmsSectionBO.cmsSectionContentBOList[0].galleryList[0].galleryDataBOList.forEach(element => {
                element.embeddedUrl = this.getSafeUrl(element.path);
              });
            }
          });
        }
        this.homePageDataList = result;


        // result.forEach((element: any) => {
        //   if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
        //     element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
        //       if (element1.itemList && element1.itemList.length > 0) {
        //         element1.itemList.forEach((item: any) => {
        //           item.isAdded = false
        //           item.quantity = 1;
        //         });
        //       }
        //     });
        //   }
        // });
      }

    })



    // this.dataService.openPostRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['GET_PAGE_BY_SEQ']).subscribe(result => {
    //   this.data = result;
    //   if (this.dataService.appConstant.SUCCESS === result.status) {

    //     this.data.result.forEach((element: any) => {
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
    //         element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
    //           if (element1.itemList && element1.itemList.length > 0) {
    //             element1.itemList.forEach((item: any) => {
    //               item.isAdded = false
    //               item.quantity = 1;
    //             });
    //           }
    //         });
    //       }
    //     });

    //     let isPushes = false;
    //     this.data.result.forEach(element => {
    //       isPushes = false;
    //       this.previewDataList.forEach(element1 => {
    //         if (element1.seqNo == element.seqNo) {
    //           isPushes = true
    //         }
    //       });
    //       if (!isPushes) {
    //         if (!element.showOnlyLoginUser) {
    //           this.previewDataList.push(element)
    //         } else {
    //           if (this.user) {
    //             this.previewDataList.push(element)
    //           }
    //         }
    //       }
    //     });

    //     this.freshPreviewDataList = this.previewDataList;



    //     this.previewDataList.forEach((element, key) => {
    //       element.previewListIndex = key
    //       //DONE
    //       if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS7188529240') {


    //         if (!element.showOnlyLoginUser) {
    //           this.mainSlider = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.mainSlider = element
    //           }
    //         }


    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH0145422180') {
    //         if (!element.showOnlyLoginUser) {
    //           this.topCategoryView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.topCategoryView = element
    //           }
    //         }

    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3859218777') {


    //         if (!element.showOnlyLoginUser) {
    //           this.topBrandView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.topBrandView = element
    //           }
    //         }

    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH4784391729') {
    //         if (!element.showOnlyLoginUser) {
    //           this.topProductView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.topProductView = element
    //           }
    //         }

    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH6829117111') {
    //         element.cmsSectionBO.noOfRows=1;
    //         if(element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length>0){
    //           let temp=[];
    //           temp.push(element.cmsSectionBO.cmsSectionContentBOList[0]);
    //           element.cmsSectionBO.cmsSectionContentBOList=temp;
    //         }

    //         if (!element.showOnlyLoginUser) {
    //           this.bestSellerProductList = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.bestSellerProductList = element
    //           }
    //         }

    //         if(this.bestSellerProduct &&  this.bestSellerProduct.cmsSectionBO && this.bestSellerProduct.cmsSectionBO.cmsSectionContentBOList
    //           && this.bestSellerProduct.cmsSectionBO.cmsSectionContentBOList.length>0){
    //             this.bestSellerProduct.cmsSectionBO.cmsSectionContentBOList= this.bestSellerProduct.cmsSectionBO.cmsSectionContentBOList[0];
    //         }
    //       }


    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH5387819003') {


    //         if (!element.showOnlyLoginUser) {
    //           this.websiteProductList = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.websiteProductList = element
    //           }
    //         }


    //       }

    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH7173940210') {



    //         if (!element.showOnlyLoginUser) {
    //           this.recommendationView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.recommendationView = element
    //           }
    //         }

    //       }

    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3057948827') {

    //         if (!element.showOnlyLoginUser) {
    //           this.recentlyView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.recentlyView = element
    //           }
    //         }


    //       }

    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH2092496496') {

    //         if (!element.showOnlyLoginUser) {
    //           this.frequentlyView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.frequentlyView = element
    //           }
    //         }

    //       }

    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH9303867011') {

    //         if (!element.showOnlyLoginUser) {
    //           this.galleryView = element;
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.galleryView = element
    //           }
    //         }

    //       }

    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH0322536283') {

    //         if (!element.showOnlyLoginUser) {
    //           this.testimonialView = element;
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.testimonialView = element
    //           }
    //         }

    //       }

    //       //DONE
    //       if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {

    //         if (!element.showOnlyLoginUser) {
    //           this.subSlider2 = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.subSlider2 = element
    //           }
    //         }


    //       }
    //     });


    //     if (this.user && this.cartList && this.cartList.length > 0) {
    //       this.previewDataList.forEach((element: any) => {
    //         if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
    //           element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
    //             if (element1.itemList && element1.itemList.length > 0) {
    //               element1.itemList.forEach((item: any) => {
    //                 this.cartList.forEach((cartItem: any) => {
    //                   if (item.itemUid == cartItem.itemUid) {
    //                     item.isAdded = true
    //                     item.quantity = cartItem.quantity;
    //                     item.cartId = cartItem.cartId;
    //                   }
    //                 });
    //               });
    //             }
    //           });
    //         }
    //       });
    //     }

    //     this.isLoading = false;
    //   } else {
    //     this.isLoading = false;
    //   }
    // }, err => {
    //   this.dataService.throwError(err)
    // })
  }
  // getYouTubeVideoId(url: string): string {
  //   const urlObject = new URL(url);
  //   const searchParams = new URLSearchParams(urlObject.search);

  //   if (urlObject.hostname === 'www.youtube.com' && urlObject.pathname === '/watch') {
  //     return searchParams.get('v') || '';
  //   }

  //   if (urlObject.hostname === 'youtu.be') {
  //     return urlObject.pathname.substr(1) || '';
  //   }

  //   return '';
  // }
  getYouTubeVideoId(link: string): string {
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|shorts\/)|youtu\.be\/)([^"&?\/\s]{11})/;
    const matches = link.match(regex);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return '';
  }
  getSafeUrl(url: string): SafeResourceUrl {
      // return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.getYouTubeVideoId(url));
      const videoId = this.getYouTubeVideoId(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }


  public addToCart(productListIndex: any, cmsSectionContentIndex: any, itemIndex: any): void {
    this.productListIndex = productListIndex;
    this.cmsSectionContentIndex = cmsSectionContentIndex;
    this.itemIndex = itemIndex;
    if (this.user && this.user.userUniqueId) {
      if (this.previewDataList && this.previewDataList[this.productListIndex].cmsSectionBO && this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList
        && this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList &&
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList.length > 0) {
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isLoading = true;
        this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex];
      }
      this.proceedToAdd.userUid = this.user.userUniqueId;
      this.proceedToAdd.quantity = 1;
      this.dataService.postRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['ADD_TO_CART']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = true;
          this.dataService.appConstant.successMsg("Item added into cart....")
          this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isLoading = false;
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      }, err => {
        this.dataService.throwError(err)
      })
    } else {

      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = true;
      let product = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex];
      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
      if (cartList && cartList.some(cart => cart.itemUid === product.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === product.itemUid) {
            cart.quantity = cart.quantity + 1;
          }

        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      } else {
        let tempCartObj = {
          'quantity': null, "itemUid": null, "salePrice": null,
          "discountUid": null, "displayName": null, "brandName": null, "varientStr": null, "isMrpDiscount": null,
          "isBuyXGetYDiscount": null, "itemName": null, "discPerc": null, "discountName": null
          , "discAmt": null, "finalPrice": null, "withoutDiscAmount": null, "mrp": null, "totalFreeQty": null, "imageFilePath": null
          , "isStockAvailable": null
        };
        tempCartObj.quantity = 1;
        tempCartObj.itemUid = product.itemUid;
        tempCartObj.displayName = product.displayName;
        tempCartObj.salePrice = product.finalPrice;
        tempCartObj.discountUid = product.discountUid;
        tempCartObj.isBuyXGetYDiscount = product.isBuyXGetYDiscount;
        tempCartObj.itemName = product.itemName;
        tempCartObj.displayName = product.itemName;
        tempCartObj.varientStr = product.varientStr;
        tempCartObj.isMrpDiscount = product.isMrpDiscount;
        tempCartObj.discPerc = product.discPerc;
        tempCartObj.discountName = product.discountName;
        tempCartObj.discAmt = product.discAmt;
        tempCartObj.finalPrice = product.finalPrice;
        tempCartObj.withoutDiscAmount = product.withoutDiscAmount;
        tempCartObj.mrp = product.mrp;
        tempCartObj.totalFreeQty = product.totalFreeQty;
        tempCartObj.imageFilePath = product.imageFilePath;
        tempCartObj.isStockAvailable = true;
        let tempCartList = [];
        if (cartList) {
          tempCartList = JSON.parse(localStorage.getItem('tempCartList'));
        }
        tempCartList.push(tempCartObj);
        localStorage.setItem("tempCartList", JSON.stringify(tempCartList));

      }
      this.dataService.getCartList();
      this.updateProductList();
    }


    // sessionStorage.setItem('pageName', "HomePage")
    // sessionStorage.setItem('productListIndex', this.productListIndex)
    // sessionStorage.setItem('cmsSectionContentIndex', this.cmsSectionContentIndex)
    // sessionStorage.setItem('itemIndex', this.itemIndex)
    // this.dataService.sendMessage("ProceedForLogin")

  }
  updateProductList() {
    this.previewDataList = this.freshPreviewDataList;
    //CHECK ITEM ALREADY ADDED INTO CART OR NOT
    this.cartList = JSON.parse(localStorage.getItem("cartList"));
    if (this.user && this.cartList && this.cartList.length > 0) {
      this.previewDataList.forEach((element: any) => {
        if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
          element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
            if (element1.itemList && element1.itemList.length > 0) {
              element1.itemList.forEach((item: any) => {
                this.cartList.forEach((cartItem: any) => {
                  if (item.itemUid == cartItem.itemUid) {
                    item.isAdded = true
                    item.quantity = cartItem.quantity;
                    item.cartId = cartItem.cartId;
                  }
                });
              });
            }
          });
        }
      });
    } else {
      let tempCartList = JSON.parse(localStorage.getItem("tempCartList"));
      this.previewDataList.forEach((element: any) => {
        if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
          element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
            if (element1.itemList && element1.itemList.length > 0) {
              element1.itemList.forEach((item: any) => {
                item.isAdded = false
                item.quantity = 1;

                if (tempCartList && tempCartList.length > 0) {
                  tempCartList.forEach(element3 => {
                    if (element3.itemUid == item.itemUid) {
                      item.isAdded = true
                      item.quantity = element3.quantity;
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }
  public updateCartQuantity(productListIndex: any, cmsSectionContentIndex: any, itemIndex: any, type: any): void {
    this.productListIndex = productListIndex;
    this.cmsSectionContentIndex = cmsSectionContentIndex;
    this.itemIndex = itemIndex;
    if (this.user && this.user.userUniqueId) {
      this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex];
      if (type == "increase") {
        this.proceedToAdd.quantity += 1;
      } else {
        this.proceedToAdd.quantity -= 1;
      }

      if (this.proceedToAdd.quantity < 1) {
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.removeItemFromCart(this.proceedToAdd.cartId);
      } else {
        this.dataService.putRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {
      this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex];
      let Qty = this.proceedToAdd.quantity;
      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.removeItemFromTempCart(this.proceedToAdd.itemUid);
      } else {
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = Qty;
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === this.proceedToAdd.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === this.proceedToAdd.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }

      this.dataService.getCartList();
      this.updateProductList();
    }


  }
  public removeItemFromTempCart(itemUid: String): void {
    let cartList = JSON.parse(localStorage.getItem('tempCartList'));
    if (cartList && cartList.some(cart => cart.itemUid === itemUid)) {
      let index = cartList.findIndex(cart => cart.itemUid === itemUid);
      cartList.splice(index, 1);
      localStorage.setItem("tempCartList", JSON.stringify(cartList));
    }
  }


  public removeItemFromCart(carId: any): void {
    this.dataService.deleteRequest(this.dataService.appConstant.SERVER_URLS['EDIT_CART'] + carId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.dataService.getCartList();
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    });
  }

  // on banner click
  onBannerClick(imgs) {

    let brands: boolean = false;
    let brandUid: any = [];
    let category: boolean = false;
    let categoryUid: any = [];
    let item: boolean = false;
    let itemUid: any = [];
    let discountUid: any = [];
    let discount: boolean = false;
    if (imgs.sliderLinkType == 2 || imgs.sliderLinkType == 1) {
      let url = imgs.sliderLinkValue
      window.open(url, '_blank');
    } else if (imgs.sliderLinkType == 5) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.itemUid) {
            item = true;
            itemUid.push(element.itemUid);
          }
        });
        if (item) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderItemUids: itemUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 6) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.itemUid) {
            item = true;
            itemUid.push(element.itemUid);
          }
        });
        if (item) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderItemUids: itemUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.brandUid) {
            item = true;
            brandUid.push(element.brandUid);
          }
        });
        if (item) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderBrandUids: brandUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 7) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.categoryUid) {
            category = true;
            categoryUid.push(element.categoryUid);
          }
        });

        if (category) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderCategorys: categoryUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 7) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.itemUid) {
            item = true;
            itemUid.push(element.itemUid);
          }
        });

        if (item) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderItemUids: itemUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 8) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.discountUid) {
            discount = true;
            discountUid.push(element.discountUid);
          }
        });

        if (discount) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderDiscountUids: discountUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    }
    else {
    }
  }


  slidePrev() {
    $('#carouselExampleControls').carousel('prev');
  }

  slideNext() {
    $('#carouselExampleControls').carousel('next');
  }
  slidePrev1() {
    $('#carouselExampleControls1').carousel('prev');
  }

  slideNext1() {
    $('#carouselExampleControls1').carousel('next');
  }
  slidePrev2() {
    $('#carouselExampleControls2').carousel('prev');
  }

  slideNext2() {
    $('#carouselExampleControls2').carousel('next');
  }
  callPhoneNumber() {
    window.open("tel:+91 8261983990", "_self");
  }

  apnagrocerySlider: OwlOptions = {
    items: 4,
    loop: false,
    dots: false,
    nav: true,
    autoplay: true,
    navText: ['<span class="custom-nav-button"><i class="fa fa-chevron-left"></i></span>', // Previous button
      '<span class="custom-nav-button mr-3 ml-3"><i class="fa fa-chevron-right"></i></span>' // Next button]
    ],
    responsive: {
      0: {
        items: 2
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      }
    }
  };

  gallerySlider: OwlOptions = {
    loop: false,
    items: 6,
    autoWidth: true,
    mouseDrag: true,
    autoplay: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
    responsive: {
      0: {
        items: 1.5
      },
      400: {
        items: 1.5
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: false
  };
  galleryVideoSlider: OwlOptions = {
    loop: false,
    items: 4,
    autoWidth: true,
    mouseDrag: true,
    autoplay: false,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: true
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element1 = document.getElementById('desktopnavebar');
    if (window.pageYOffset > 10) {
      // let element = document.getElementById('navbar');
      // element.classList.add('sticky');
      element1.classList.add('desktop-header-sticky');
    } else {
      // let element = document.getElementById('navbar');
      // element.classList.remove('sticky');
      element1.classList.remove('desktop-header-sticky');
    }
  }


  checkAddToCard(itemUid: String): Boolean {
    let tempCartList = JSON.parse(localStorage.getItem("tempCartList"));
    if (this.user && this.cartList && this.cartList.length > 0) {
      const foundCart = this.cartList.find(cartObj => cartObj.itemUid == itemUid);
      if (foundCart) {
        return true;
      } else {
        return false;
      }
    } else if (tempCartList && tempCartList.length > 0) {
      const foundCart = tempCartList.find(cartObj => cartObj.itemUid == itemUid);
      if (foundCart) {
        return true;
      } else {
        return false;
      }
    }
  }

  getCartQty(itemUid: String): number {
    let tempCartList = JSON.parse(localStorage.getItem("tempCartList"));
    if (this.user && this.cartList && this.cartList.length > 0) {
      const foundCart = this.cartList.find(cartObj => cartObj.itemUid == itemUid);
      if (foundCart) {
        return foundCart.quantity;
      } else {
        return 0;
      }
    } else if (tempCartList && tempCartList.length > 0) {
      const foundCart = tempCartList.find(cartObj => cartObj.itemUid == itemUid);
      if (foundCart) {
        return foundCart.quantity;
      } else {
        return 0;
      }
    }
  }

  getCartObj(itemUid: String): any {
    let tempCartList = JSON.parse(localStorage.getItem("tempCartList"));
    if (this.user && this.cartList && this.cartList.length > 0) {
      const foundCart = this.cartList.find(cartObj => cartObj.itemUid == itemUid);
      if (foundCart) {
        return foundCart;
      } else {
        return null;
      }
    } else if (tempCartList && tempCartList.length > 0) {
      const foundCart = tempCartList.find(cartObj => cartObj.itemUid == itemUid);
      if (foundCart) {
        return foundCart;
      } else {
        return null;
      }
    }
  }

  public addToCartV1(item: any): void {
    if (this.user && this.user.userUniqueId) {
      this.proceedToAdd.itemUid = item.itemUid;
      this.proceedToAdd.salePrice = item.finalPrice;
      this.proceedToAdd.userUid = this.user.userUniqueId;
      this.proceedToAdd.quantity = 1;
      this.dataService.postRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['ADD_TO_CART']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.dataService.appConstant.successMsg("Item added into cart....")
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      }, err => {
        this.dataService.throwError(err)
      })
    } else {

      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid == item.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === item.itemUid) {
            cart.quantity = cart.quantity + 1;
          }

        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      } else {
        let tempCartObj = {
          'quantity': null, "itemUid": null, "salePrice": null,
          "discountUid": null, "displayName": null, "brandName": null, "varientStr": null, "isMrpDiscount": null,
          "isBuyXGetYDiscount": null, "itemName": null, "discPerc": null, "discountName": null
          , "discAmt": null, "finalPrice": null, "withoutDiscAmount": null, "mrp": null, "totalFreeQty": null, "imageFilePath": null
          , "isStockAvailable": null
        };
        tempCartObj.quantity = 1;
        tempCartObj.itemUid = item.itemUid;
        tempCartObj.displayName = item.displayName;
        tempCartObj.salePrice = item.finalPrice;
        tempCartObj.discountUid = item.discountUid;
        tempCartObj.isBuyXGetYDiscount = item.isBuyXGetYDiscount;
        tempCartObj.itemName = item.itemName;
        tempCartObj.displayName = item.itemName;
        tempCartObj.varientStr = item.varientStr;
        tempCartObj.isMrpDiscount = item.isMrpDiscount;
        tempCartObj.discPerc = item.discPerc;
        tempCartObj.discountName = item.discountName;
        tempCartObj.discAmt = item.discAmt;
        tempCartObj.finalPrice = item.finalPrice;
        tempCartObj.withoutDiscAmount = item.withoutDiscAmount;
        tempCartObj.mrp = item.mrp;
        tempCartObj.totalFreeQty = item.totalFreeQty;
        tempCartObj.imageFilePath = item.imageFilePath;
        tempCartObj.isStockAvailable = true;
        let tempCartList = [];
        if (cartList) {
          tempCartList = JSON.parse(localStorage.getItem('tempCartList'));
        }
        tempCartList.push(tempCartObj);
        localStorage.setItem("tempCartList", JSON.stringify(tempCartList));

      }
      this.dataService.getCartList();
      // this.updateProductList();
    }

  }


  public updateCartQuantityV1(item: any, type: String): void {
    if (this.user && this.user.userUniqueId) {
      let cartObj = this.getCartObj(item.itemUid);
      item.quantity = cartObj.quantity;
      item.cartId = cartObj.cartId;
      if (type == "increase") {
        item.quantity += 1;
      } else {
        item.quantity -= 1;
      }
      if (item.quantity < 1) {
        const foundCart = this.cartList.find(cartObj => cartObj.itemUid = item.itemUid);
        if (foundCart) {
          this.removeItemFromCartV1(foundCart.cartId);
        }
      } else {
        this.dataService.putRequest(item, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
          this.data = response;
          if (this.dataService.appConstant.SUCCESS === response.status) {
            this.dataService.getCartList();
          }
        }, err => {
          this.dataService.throwError(err)
        })
      }
    } else {

      let Qty = this.getCartQty(item.itemUid);
      if (type == "increase") {
        Qty += 1
      } else {
        Qty -= 1;
      }

      if (Qty < 1) {
        this.removeItemFromTempCart(item.itemUid);
      }


      let cartList = JSON.parse(localStorage.getItem('tempCartList'));

      if (cartList && cartList.some(cart => cart.itemUid === item.itemUid)) {
        cartList.forEach(cart => {
          if (cart.itemUid === item.itemUid) {
            cart.quantity = Qty;
          }
        });
        localStorage.setItem("tempCartList", JSON.stringify(cartList));
      }

      this.dataService.getCartList();
      // this.updateProductList();
    }


  }


  public removeItemFromCartV1(carId: any): void {
    this.dataService.deleteRequest(this.dataService.appConstant.SERVER_URLS['EDIT_CART'] + carId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.dataService.getCartList();
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    });
  }

 



}






